<template>
    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4 mb-3">
        <form class="row" @submit.prevent="patchQuestion">
            <div class="form-group col-lg-6">
                <label for="">Test Type</label>
                <select @change="onChangeType" class="form-control" v-model="type" required>
                    <option value="1">With Video</option>
                    <option value="2">With Audio</option>
                    <option value="3">Only Text</option>
                </select>
            </div>
            <div class="form-group col-lg-6" v-if="type == 1">
                <label for="">Video File</label><br>
                <file-upload
                class="btn btn-lg btn-current mr-1"
                @input="updateFile"
                @input-file="inputFile"
                @input-filter="fileFilter"
                :custom-action="fileAction"
                accept="video/mp4"
                ref="upload" required>
                <i class="fa fa-plus"></i>
                Upload Video
                </file-upload>

                <a v-if="path" :href=" path" target="_blank" class="btn btn-lg btn-success">Current File</a>

                <button type="button" class="btn btn-lg btn-danger ml-1"  v-if="!$refs.upload || $refs.upload.active" @click.prevent="$refs.upload.active = false">
                <i class="fa fa-stop" aria-hidden="true"></i>
                Stop Upload
                </button>
            </div>
            <div class="form-group col-lg-6" v-if="type == 2">
                <label for="">Audio File</label><br>
                <file-upload
                class="btn btn-lg btn-current mr-1"
                @input="updateFile"
                @input-file="inputFile"
                @input-filter="fileFilter"
                :custom-action="fileAction"
                accept="audio/mpeg"
                ref="upload" required>
                <i class="fa fa-plus"></i>
                Upload Audio
                </file-upload>

                <a v-if="path" :href=" path" target="_blank" class="btn btn-lg btn-success">Current File</a>

                <button type="button" class="btn btn-lg btn-danger ml-1"  v-if="!$refs.upload || $refs.upload.active" @click.prevent="$refs.upload.active = false">
                <i class="fa fa-stop" aria-hidden="true"></i>
                Stop Upload
                </button>
            </div>
            <div class="form-group col-lg-12">
                <label for="">Question</label><br>
                <vue-editor v-model="content"/>
            </div>
            <div class="form-group col-lg-12">
                <label for="">Submission Type</label>
                <select class="form-control" v-model="submission_type" required>
                    <option value="1">Multiple Choice</option>
                    <option value="2">Multiple Answer</option>
                </select>
            </div>
            <div v-if="submission_type == 1" class="form-group col-12">
                <div class="card border-0 pl-3">
                    <div class="input-group mb-2" v-for="(opt, j) in option" :key="j">
                        <div class="input-group-text bg-transparent border-0">
                            <input v-model="answer[0]" :value="opt.id" class="form-check-input mt-0" type="radio" :id="'answer['+j+']'" name="answer" :checked="opt.is_answer">
                        </div>
                        <!-- <input type="text" v-model="opt.option" class="form-control" :placeholder="'Enter your option ' + j" required> -->
                        <div class="row w-100">
                            <div class="col-8">
                                <input type="text" v-model="opt.option" class="form-control" :placeholder="'Enter your option ' + j" required>
                            </div>
                            <div class="col-4">
                                <select v-model="opt.category" class="form-control" required>
                                    <option value="" selected disabled>Select Category</option>
                                    <option value="K">K</option>
                                    <option value="V">V</option>
                                    <option value="R">R</option>
                                    <option value="A">A</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="submission_type == 2" class="form-group col-12">
                <div class="card border-0 pl-3">
                    <div class="input-group mb-2" v-for="(opt, j) in option" :key="j">
                        <div class="input-group-text bg-transparent border-0">
                            <input @click="setAnswer()" ref="checkSub" :value="opt.id" class="form-check-input mt-0" type="checkbox" :name="`answer${j}`" :checked="opt.is_answer">
                        </div>
                        <!-- <input type="text" v-model="opt.option" class="form-control" :placeholder="'Enter your option ' + j" required> -->
                        <div class="row w-100">
                            <div class="col-8">
                                <input type="text" v-model="opt.option" class="form-control" :placeholder="'Enter your option ' + j" required>
                            </div>
                            <div class="col-4">
                                <select v-model="opt.category" class="form-control" required>
                                    <option value="" selected disabled>Select Category</option>
                                    <option value="S">S</option>
                                    <option value="G">G</option>
                                    <option value="T">T</option>
                                    <option value="E">E</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group col-lg-12 text-right">
                <button type="submit" class="btn btn-current mt-3">Submit</button>
            </div>
        </form>
    </div>
</template>
<script>
import axios from 'axios'
import FileUpload from 'vue-upload-component'

export default {
    components:{
        FileUpload
    },
    props: ['data'],
    created(){
      this.quest_id = this.data.id
      this.content = this.data.question
      this.submission_type = this.data.submission_type
      this.answer = this.data.answer.split(",")
      this.type = this.data.type
      this.option = this.data.option
      this.path = this.data.path
      this.setAnswer()
    },
    data(){
        return {
            id: this.$route.params.idWa,
            quest_id:'',
            content:'',
            media: process.env.VUE_APP_URL_CLOUD,
            type: 1,
            files: [],
            submission_type: 1,
            path:'',
            rubric_id:'',
            skills: 1,
            answer: [],
            option:[]
        }
    },
    watch: {
      submission_type: function(old, news) {
        if(old == 1 && news == 2) {
            this.answer = []
        }
      }
    },
    methods:{
        setAnswer(){
          var check = this.$refs.checkSub;
          var temp =[];
          if(check && check.length) {
              check.map(el => {
                if(el.checked) {
                  temp.push(el.value)
                }
              })
              this.answer = temp
          }
        },
        onChangeType(){
            if(this.files[0] || this.path) {
                // this.deleteFile(this.files[0])
            }
        },
        async patchQuestion(){
            var data = {
                _method: 'patch',
                wa_id: this.id,
                id: this.quest_id,
                type: this.type,
                path: this.path,
                question: this.content,
                submission_type: this.submission_type,
                answer: this.answer.sort(),
                option: this.option,
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/setting/getting-start/wa/quest`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.$swal({
                    toast: true,
                    title: 'Questions!',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.$emit('update-data')
                
            }).catch(err => {
                if (err.response.status == 422) {
                    this.$swal({
                        toast: true,
                        title: 'Question!',
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        updateFile(value) {
            this.files = value
        },
        async fileAction(file) {
            return this.postFile(file)
        },
        async inputFile(newFile, oldFile) {
            if (!newFile && oldFile) {
                if (oldFile.success && oldFile.response.path) {
                    // this.deleteFile(oldFile)
                }
            }
            if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
                if (!this.$refs.upload.active) {
                    this.$refs.upload.active = true
                }
            }
        },
        fileFilter(newFile, oldFile, prevent) {
            if (this.type == 1) {
                if (newFile && !oldFile) {
                    if (!/\.(mp4)$/i.test(newFile.name)) {
                        return prevent()
                    }
                }
            } else {
                if (newFile && !oldFile) {
                    if (!/\.(mp3)$/i.test(newFile.name)) {
                        return prevent()
                    }
                }
            }
        },
        async postFile(file){
            let data = new FormData();
            data.append('dir', this.type == 1 ? 'video' : 'audio');
            data.append('attachment', file.file);
            data.append('_token', 'elingwaysecretkey');
            await axios.post(`${process.env.VUE_APP_URL_MEDIA}/api/storage/store-cloud`, data, {withCredentials: false}, {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                file.response = res.data
                this.path = res.data.path
                return res.data
            })
        },
        async deleteFile(file){
            let data = new FormData();
            data.append('path', file.response.path);
            data.append('_token', 'elingwaysecretkey');
            await axios.post(`${process.env.VUE_APP_URL_MEDIA}/api/storage/delete`, data, {withCredentials: false}, {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.files = []
                this.path = ''
                return res.data
            })
        },
    }
};
</script>